import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MedicalRecordComponent } from "./medical-record.component";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "src/app/core/authentication.guard";
import { DefaultModules } from "src/app/services/app.constants";
import { CardModule } from "primeng/card";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { AddProcedureComponent } from "./add-procedure/add-procedure.component";
import { AddVaccineComponent } from "./add-vaccine/add-vaccine.component";
import { AddFamilyMemberComponent } from "./add-family-member/add-family-member.component";
import { AddAllergyComponent } from "./add-allergy/add-allergy.component";
import { AddMedicationComponent } from "./add-medication/add-medication.component";
import { AddSocialHistoryComponent } from "./add-social-history/add-social-history.component";
import { DialogModule } from "primeng/dialog";
import { InputTextModule } from "primeng/inputtext";
import { DropdownModule } from "primeng/dropdown";
import { CalendarModule } from "primeng/calendar";
import { InfoPopupComponent } from "./info-popup/info-popup.component";
import { MultiSelectModule } from "primeng/multiselect";
import { ObservationGraphComponent } from "./observation-graph/observation-graph.component";
import { ChartModule } from "primeng/chart";
import { AddConditionComponent } from "./add-condition/add-condition.component";
import { FileUploadModule } from "primeng/fileupload";
import { AddEncounterComponent } from "./add-encounter/add-encounter.component";
import { AddMedicalReportComponent } from "./add-medical-report/add-medical-report.component";
import { AddParticipationInfoComponent } from "./add-participation-info/add-participation-info.component";
import { NumberOnlyDirective } from "./number-only.directive";
import { CheckboxModule } from "primeng/checkbox";
import { NgScrollbarModule } from "ngx-scrollbar";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ApplicationScoresComponent } from "./application-scores/application-scores.component";
import { TactusTimelineModule } from "./application-scores/tactus-timeline/tactus-timeline.module";
import { StreamingExaminationsModule } from "../../../core/components/streaming-examinations/streaming-examinations.module";
import { AddNoteComponent } from "./add-note/add-note.component";
import { React2Module } from "./application-scores/react2/react2.module";

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    component: MedicalRecordComponent,
    data: { title: "sidebar.PatientRecord.MedicalRecord" },
  },
];

@NgModule({
  declarations: [
    MedicalRecordComponent,
    InfoPopupComponent,
    AddProcedureComponent,
    AddVaccineComponent,
    AddFamilyMemberComponent,
    AddAllergyComponent,
    AddMedicationComponent,
    AddSocialHistoryComponent,
    ObservationGraphComponent,
    AddConditionComponent,
    AddEncounterComponent,
    AddMedicalReportComponent,
    AddParticipationInfoComponent,
    NumberOnlyDirective,
    ApplicationScoresComponent,
    AddNoteComponent,
  ],
  imports: [
    ...DefaultModules,
    RouterModule.forChild(routes),
    CardModule,
    CheckboxModule,
    ButtonModule,
    DialogModule,
    ReactiveFormsModule,
    DropdownModule,
    CalendarModule,
    InputTextModule,
    MultiSelectModule,
    FormsModule,
    ChartModule,
    FileUploadModule,
    NgScrollbarModule,
    InputTextareaModule,
    TactusTimelineModule,
    StreamingExaminationsModule,
    React2Module,
  ],
  exports: [
    MedicalRecordComponent,
    InfoPopupComponent,
    AddProcedureComponent,
    AddVaccineComponent,
    AddFamilyMemberComponent,
    AddAllergyComponent,
    AddMedicationComponent,
    AddSocialHistoryComponent,
    ObservationGraphComponent,
    AddConditionComponent,
    AddEncounterComponent,
  ],
})
export class MedicalRecordModule {}
