<div class="container-fluid">
  <div class="row">
    <div class="medical-record col-md-12">
      <p-card>
        <div class="header bold d-flex">
          <label class="font-size-18">{{ "Application scores" | translate }}</label>
        </div>
        <div class="content">
          <div style="height: 100%; overflow: auto">
            <div class="m-2" style="padding-right: 10px">
              <div *ngIf="!applicationScores || applicationScores.length == 0">
                {{ "ThereAreNoScoresYet!" | translate }}
              </div>
              <ng-container *ngIf="applicationScores && applicationScores.length > 0">
                <div
                  class="item"
                  [ngClass]="{ even: itemIndex % 2 == 0, odd: itemIndex % 2 == 1 }"
                  *ngFor="let item of applicationScores; let itemIndex = index"
                >
                  <div>
                    <div class="d-flex flex-column">
                      <div class="bold italic wrap d-flex">
                        <span class="flex-grow-1">
                          {{ item.item.name | translate }}
                        </span>
                        <div>
                          <button
                            pButton
                            (click)="openApplicationsScoreChart(item)"
                            icon="far fa-chart-bar"
                            class="p-button-outlined p-button-info"
                            style="color: #00a89f !important; border: none"
                          ></button>
                        </div>
                      </div>
                    </div>
                    <div class="wrap d-flex">
                      <div class="mb-1 flex-grow-1">{{ "medicalRecord.value" | translate }}:</div>
                      {{ item.points }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </p-card>
    </div>
    <div class="m-2"></div>
    <div class="medical-record col-md-12">
      <p-card>
        <div class="bold" class="header d-flex justify-content-between">
          <label class="font-size-18">{{ "TactusData" | translate }}</label>
          <button
            icon="fas fa-plus"
            class="p-button-raised p-button-success"
            pButton
            [label]="'AddTactusRecord' | translate"
            (click)="addTactusRecord()"
          ></button>
        </div>
        <div class="content">
          <div style="height: 100%; overflow: auto">
            <div class="m-2" style="padding-right: 10px">
              <ng-container *ngIf="tactusData && tactusData.length > 0">
                <ng-container *ngFor="let appData of tactusData">
                  <div class="col-md-12" *ngIf="appData && appData.length > 0">
                    <p class="bold font-size-18">- {{ tactusApps[appData[0].app].name | translate }}</p>
                    <app-tactus-timeline
                      [items]="appData"
                      (editItem)="editItem($event)"
                      (deleteItem)="deleteItem($event)"
                    >
                    </app-tactus-timeline>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </p-card>
    </div>
    <div class="m-2"></div>
    <div class="medical-record col-md-12">
      <p-card>
        <div class="bold" class="header d-flex justify-content-between">
          <label class="font-size-18">{{ "React2" | translate }}</label>
        </div>
        <div class="content">
          <div style="height: 100%; overflow: auto">
            <div class="m-2" style="padding-right: 10px">
              <ng-container *ngIf="react2Data && react2Data.length > 0">
                <!-- <ng-container *ngFor="let appData of react2Data"> -->
                <app-react2 [items]="react2Data"></app-react2>
                <!-- </ng-container> -->
              </ng-container>
            </div>
          </div>
        </div>
      </p-card>
    </div>
    <div class="m-2"></div>
    <div class="medical-record col-md-12">
      <app-streaming-examinations [userId]="patientId"></app-streaming-examinations>
    </div>
  </div>
</div>

<p-dialog
  [baseZIndex]="10000"
  [(visible)]="showApplicationsChart"
  [modal]="true"
  [style]="{ width: '700px', 'max-width': '100%', height: 'auto' }"
  positionTop="50"
>
  <div *ngIf="showApplicationsChart">
    <p-chart
      type="line"
      [data]="applicationsScoresDataSet"
      [options]="optionsLineChart"
      [height]="'65vh'"
      [width]="'100%'"
    ></p-chart>
  </div>
</p-dialog>

<p-dialog
  appendTo="body"
  *ngIf="showTactusForm"
  [header]="'AddTactusRecord' | translate"
  [(visible)]="showTactusForm"
  [modal]="true"
  [style]="{ 'min-width': '70vw', 'max-width': '100%', 'min-height': '20vh', height: 'auto' }"
  [contentStyle]="{ overflow: 'visible' }"
  (visibleChange)="tactusItemToEdit = null"
>
  <div>
    <form [formGroup]="tactusForm" novalidate (submit)="saveTactusForm()">
      <div class="p-g">
        <div class="p-g-12">
          <label>{{ "SelectApplication" | translate }}</label>
          <p-dropdown
            [options]="tactusApps"
            formControlName="app"
            optionLabel="label"
            optionValue="id"
            [placeholder]="'SelectApplication' | translate"
          ></p-dropdown>
        </div>
        <div class="p-g-12">
          <label>{{ "medicalRecord.SelectDateTime" | translate }}</label>
          <p-calendar formControlName="date" [showTime]="true"> </p-calendar>
        </div>
        <div class="p-g-12">
          <label>{{ "InsertData" | translate }}</label>
          <textarea rows="10" pInputTextarea formControlName="data"></textarea>
        </div>
        <div class="p-1"></div>
        <div class="separator"></div>
        <div class="p-1"></div>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <button
            pButton
            type="submit"
            icon="pi pi-plus"
            [label]="'general.button.texts.save' | translate"
            class="p-button-rounded"
            [disabled]="formSubmitting"
          ></button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
