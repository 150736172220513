import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Helpers } from "../../../services/app.helpers";
import { ToastService } from "../../toast.service";
import { TokenService } from "../../token.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { AuthenticationService } from "../../../services/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-change-password-form",
  templateUrl: "./change-password-form.component.html",
  styleUrls: ["./change-password-form.component.scss"],
})
export class ChangePasswordFormComponent implements OnInit {
  @Output() close = new EventEmitter<any>();
  form!: FormGroup;
  error = "";
  isFormSubmitting = false;
  passwordFieldType = "password";

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get("password")?.value;
    const confirmPassword: string = control.get("passwordVerification")?.value;
    if (password !== confirmPassword) {
      control.get("passwordVerification")?.setErrors({ NoPassswordMatch: true });
    }
  }

  constructor(
    private fb: FormBuilder,
    private helpers: Helpers,
    private translateService: TranslateService,
    private toastService: ToastService,
    private tokenService: TokenService,
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.createForm();
  }

  togglePasswordType() {
    this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
  }

  createForm() {
    this.form = this.fb.group(
      {
        password: [
          "",
          {
            validators: [Validators.required, Validators.pattern("((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})")],
            updateOn: "blur",
          },
        ],
        passwordVerification: ["", { validators: [Validators.required], updateOn: "blur" }],
      },
      { validator: ChangePasswordFormComponent.passwordMatchValidator }
    );
  }

  submit(event: any) {
    Helpers.markFormGroupDirty(this.form);
    Helpers.markFormGroupTouched(this.form);

    if (!this.form.valid) {
      this.toastService.errorMessage(this.translateService.instant("account.invalidForm"));
      return;
    }

    const model = this.form.getRawValue();

    this.isFormSubmitting = true;

    this.httpClient
      .post(`${environment.serverPath}/api/users/change-password`, { password: model.password })
      .subscribe({
        next: (data) => {
          this.isFormSubmitting = false;
          if (data) {
            this.toastService.infoMessage(
              this.translateService.instant("Logging out... Please login again with the new password")
            );
            this.close.emit(true);
            this.authService.logout();
            this.router.navigate(["login"]);
          }
        },
        error: (err) => {
          this.isFormSubmitting = false;
          // this.close.emit(true);
          console.log(err);
          this.toastService.generalErrorMessage();
        },
      });
  }
}
