import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";
import { React2Item } from "./react2.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-react2",
  templateUrl: "./react2.component.html",
  styleUrls: ["./react2.component.scss"],
})
export class React2Component implements OnInit {
  _items: Array<React2Item> = [];

  @Input()
  get items() {
    return this._items;
  }
  set items(value: Array<React2Item>) {
    function secondsToString(milliseconds: number) {
      let seconds = milliseconds;
      var numYears = Math.round(Math.floor(seconds / 31536000));
      var numDays = Math.round(Math.floor((seconds % 31536000) / 86400));
      var numHours = Math.round(Math.floor(((seconds % 31536000) % 86400) / 3600));
      var numMinutes = Math.round(Math.floor((((seconds % 31536000) % 86400) % 3600) / 60));
      var numSeconds = Math.round((((seconds % 31536000) % 86400) % 3600) % 60);

      let result = "";
      if (numYears) result = result + numYears + "y ";
      if (numDays) result = result + numDays + "d ";
      if (numHours) result = result + numHours + "h ";
      if (numMinutes) result = result + numMinutes + "mins ";
      if (numSeconds) result = result + numSeconds + "s ";

      return result;
    }

    value.sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
    const temp = value.map((x) => ({
      ...x,
      data: {
        ...x.data,
        averageTimeCorrect1stAverage: secondsToString(x.data.averageTimeCorrect1stAverage),
        averageTimeCorrect2ndAverage: secondsToString(x.data.averageTimeCorrect2ndAverage),
        averageTimeIncorrectAverage: secondsToString(x.data.averageTimeIncorrectAverage),
        totalTime: secondsToString(x.data.totalTime),
      },
    }));

    this._items = temp as any;
  }

  constructor(private translationService: TranslateService) {}

  ngOnInit() {}

  formatDate(date: Date, format: string) {
    return moment(date).format(format);
  }
}
