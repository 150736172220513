<router-outlet></router-outlet>
<p-toast position="top-right"></p-toast>
<p-confirmDialog [header]="'alerts.confirmTitle' | translate" icon="pi pi-exclamation-triangle"> </p-confirmDialog>
<!-- <ngx-loading-bar color="#ef3e5c"></ngx-loading-bar> -->
<!-- <app-chatbot-ui *ngIf="isLoggedIn" [showChatBot]="true"></app-chatbot-ui> -->
<div *ngIf="showChangePasswordPopUp">
  <div class="overlay global"></div>
  <div class="spinner global">
    <app-change-password-form (close)="showChangePasswordPopUp = !showChangePasswordPopUp"></app-change-password-form>
  </div>
</div>
