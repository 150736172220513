<div class="card">
  <!-- <div class="card-header">
    <div class="flex-grow-1 title">This is the title</div>
  </div> -->
  <div class="card-body flex-grow-1" style="padding: 10px">
    <div class="iti-timeline-container">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="react2">
            <div *ngIf="items?.length === 0" class="center-text">
              * {{ "No records found for the patient" | translate }}
            </div>
            <ng-container *ngIf="items?.length >= 1">
              <p-card [style]="{ height: '100%', 'min-width': '200px' }">
                <div class="text-top">
                  {{ "QuestionsAttempted" | translate }}
                </div>
                <div class="text-bold-number">
                  {{ items[0].data.attemptedExercises }}
                </div>
                <br />
                <br />
                <br />
                <br />
                <div class="text-time">{{ "TotalTime" | translate }}:</div>
                <div class="text-time-number">{{ items[0].data.totalTime }}</div>
              </p-card>

              <p-card [style]="{ height: '100%', 'min-width': '200px' }">
                <div class="text-top">
                  {{ "CorrectOn1stAttempt" | translate }}
                </div>
                <div class="text-bold-number">
                  {{ items[0].data.correct1stAttempt }}
                </div>
                <br />
                <div class="text-percentage">{{ items[0].data.percentCorrect1stAttempt }}%</div>
                <div
                  class="text-bar-percentage text-bar-percentage-first"
                  [style]="{ width: items[0].data.percentCorrect1stAttempt + '%' }"
                ></div>
                <br />
                <div class="text-time">{{ "AverageTime" | translate }}:</div>
                <div class="text-time-number">{{ items[0].data.averageTimeCorrect1stAverage }}</div>
              </p-card>

              <p-card [style]="{ height: '100%', 'min-width': '200px' }">
                <div class="text-top">
                  {{ "CorrectOn2ndAttempt" | translate }}
                </div>
                <div class="text-bold-number">
                  {{ items[0].data.correct2ndAttempt }}
                </div>
                <br />
                <div class="text-percentage">{{ items[0].data.percentCorrect2ndAttempt }}%</div>
                <div
                  class="text-bar-percentage text-bar-percentage-second"
                  [style]="{ width: items[0].data.percentCorrect2ndAttempt + '%' }"
                ></div>
                <br />
                <div class="text-time">{{ "AverageTime" | translate }}:</div>
                <div class="text-time-number">{{ items[0].data.averageTimeCorrect2ndAverage }}</div>
              </p-card>

              <p-card [style]="{ height: '100%', 'min-width': '200px' }">
                <div class="text-top">
                  {{ "Incorrect" | translate }}
                </div>
                <div class="text-bold-number">
                  {{ items[0].data.incorrect }}
                </div>
                <br />
                <div class="text-percentage">{{ items[0].data.percentIncorrect }}%</div>
                <div
                  class="text-bar-percentage text-bar-percentage-incorrect"
                  [style]="{ width: items[0].data.percentIncorrect + '%' }"
                ></div>
                <br />
                <div class="text-time">{{ "AverageTime" | translate }}:</div>
                <div class="text-time-number">{{ items[0].data.averageTimeIncorrectAverage }}</div>
              </p-card>

              <p-card [style]="{ height: '100%', 'min-width': '200px' }">
                <div class="text-top">
                  {{ "NotAttempted" | translate }}
                </div>
                <div class="text-bold-number">
                  {{ items[0].data.notAttempted }}
                </div>
              </p-card>
            </ng-container>
          </div>
          <ng-container *ngIf="items?.length >= 1">
            <br />
            <div class="text-time">
              <span>{{ "LastUpdated" | translate }}:&nbsp;</span>
              <span>{{ formatDate(items[0].createdAt, "DD/MM/YYYY, HH:mm") }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
