import { NgModule } from "@angular/core";
import { DefaultModules } from "src/app/services/app.constants";
import { React2Component } from "./react2.component";
import { CardModule } from "primeng/card";

@NgModule({
  declarations: [React2Component],
  imports: [...DefaultModules, CardModule],
  exports: [React2Component],
})
export class React2Module {}
