<header class="clear">
  <div class="container-fluid">
    <div class="row header--container align-items-center">
      <div>
        <div class="right-menu">
          <div class="d-inline-block relative">
            <div ngbDropdown placement="bottom-right" class="d-inline-block">
              <button
                class="btn btn-link"
                id="dropdownBasic3"
                ngbDropdownToggle
                style="
                  padding-left: 25px;
                  padding-right: 25px;
                  width: auto !important;
                  background: #b66b78;
                  color: #ffffff !important;
                "
              >
                <span style="color: #fff !important; display: inline-block; padding-right: 5px">{{
                  getLanguageName()
                }}</span>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic4">
                <button ngbDropdownItem (click)="changeLanguage('en')">
                  {{ "general.language_en" | translate }}
                </button>
                <button ngbDropdownItem (click)="changeLanguage('es')">
                  {{ "general.language_es" | translate }}
                </button>
                <button ngbDropdownItem (click)="changeLanguage('pt')">
                  {{ "general.language_pt" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<router-outlet></router-outlet>
