import { NgModule } from "@angular/core";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ThemeModule } from "./theme/theme.module";
import { FormsModule } from "@angular/forms";
import { TokenInterceptor } from "./core/token.interceptor";
import { PatientRecordComponent } from "./pages/patient-record/patient-record.component";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { Helpers } from "./services/app.helpers";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmationService } from "primeng/api";
import { environment } from "src/environments/environment";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { CompareLayoutComponent } from "./pages/patients/compare/compare-layout/compare-layout.component";
import { MultiSelectModule } from "primeng/multiselect";
import { CalendarModule } from "primeng/calendar";
import { StepsModule } from "primeng/steps";
import { StepsComponentsModule } from "./pages/patient-record/steps/steps-components.module";
import { BrowserModule } from "@angular/platform-browser";
import { DialogModule } from "primeng/dialog";
import { InviteCaregiverFormModule } from "./pages/patient-record/invite-caregiver-form/invite-caregiver-form.module";
import { ChatbotUiModule } from "./core/components/chatbot-ui/chatbot-ui.module";
import { FullCalendarModule } from "@fullcalendar/angular";
import { PatientCareTeamModule } from "./pages/patient-record/care-plan/patient-care-team/patient-care-team.module";
import { ReportExportPdfComponent } from "./pages/patient-record/report-export-pdf/report-export-pdf.component";
import { ChangePasswordFormModule } from "./core/components/change-password-form/change-password-form.module";

const config: SocketIoConfig = {
  url: environment.sockets.url,
  options: {
    path: environment.sockets.path,
    transports: environment.sockets.transports,
  },
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/translations/");
}

@NgModule({
  declarations: [AppComponent, PatientRecordComponent, CompareLayoutComponent, ReportExportPdfComponent],
  imports: [
    BrowserModule,
    SocketIoModule.forRoot(config),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ToastModule,
    ConfirmDialogModule,
    MultiSelectModule,
    CalendarModule,
    DialogModule,
    InviteCaregiverFormModule,
    FullCalendarModule,
    PatientCareTeamModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StepsModule,
    ThemeModule,
    StepsComponentsModule,
    FullCalendarModule,
    ChatbotUiModule,
    ChangePasswordFormModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    MessageService,
    ConfirmationService,
    Helpers,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
