<p-card>
  <form
    [formGroup]="form"
    (ngSubmit)="submit($event)"
    novalidate
    style="height: 100%"
    class="form-group d-flex flex-column justify-content-between"
  >
    <div class="form-group">
      <label for="password">{{ "You need to change your password to a new one" | translate }} *</label>
      <div style="position: relative">
        <input
          class="form-control"
          [placeholder]="'registrationPage.passwordPlaceholder' | translate"
          id="password"
          [type]="passwordFieldType"
          size="30"
          style="padding-right: 35px"
          formControlName="password"
          pInputText
        />
        <span
          *ngIf="passwordFieldType === 'password'"
          style="position: absolute; top: 25%; right: 10px; cursor: pointer"
          (click)="togglePasswordType()"
        >
          <i class="fas fa-eye"></i>
        </span>
        <span
          *ngIf="passwordFieldType === 'text'"
          style="position: absolute; top: 25%; right: 10px; cursor: pointer"
          (click)="togglePasswordType()"
        >
          <i class="fas fa-eye-slash"></i>
        </span>
      </div>
      <label
        [ngStyle]="{ color: form.controls['password'].hasError('pattern') ? 'red' : '#333' }"
        style="font-size: 12px"
      >
        {{ "registrationPage.8chars" | translate }}
      </label>
    </div>

    <div class="form-group">
      <label for="passwordVerification">{{ "registrationPage.verifyPasswordPlaceholder" | translate }} *</label>
      <input
        class="form-control"
        [placeholder]="'registrationPage.passwordPlaceholder' | translate"
        id="passwordVerification"
        type="password"
        size="30"
        formControlName="passwordVerification"
        pInputText
      />
      <label class="text-danger" *ngIf="form.controls['passwordVerification'].hasError('NoPassswordMatch')">
        {{ "registrationPage.noMatch" | translate }}
      </label>
    </div>

    <div class="text-center mb-1 d-flex" *ngIf="form">
      <button
        pButton
        type="submit"
        [disabled]="isFormSubmitting"
        [label]="'general.button.texts.Submit' | translate"
      ></button>
    </div>
  </form>
</p-card>
