import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { DefaultModules } from "src/app/services/app.constants";
import { ChangePasswordFormComponent } from "./change-password-form.component";
import { TranslateModule } from "@ngx-translate/core";
import { CardModule } from "primeng/card";

@NgModule({
  declarations: [ChangePasswordFormComponent],
  imports: [
    ...DefaultModules,
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    FormsModule,
    TranslateModule.forChild(),
    CardModule,
  ],
  exports: [ChangePasswordFormComponent],
})
export class ChangePasswordFormModule {}
